<template>
  <section
    class="border border-danger rounded shadow">
    <video ref="video"></video>
    <canvas ref="canvasOutput"></canvas>
  </section>
</template>

<script>
import jsqr from 'jsqr'

export default {
  mounted () {
    const video = this.$refs.video
    const canvas = document.createElement('canvas')
    /**
     * @type HTMLCanvasElement
     */
    const canvasOutput = this.$refs.canvasOutput
    const ctx = canvas.getContext('2d')
    const ctxOutput = canvasOutput.getContext('2d')

    const perVideoFrame = () => {
      const width = video.videoWidth
      const height = video.videoHeight
      let code = null

      canvas.width = width
      canvas.height = height

      if (width && height) {
        ctx.clearRect(0, 0, width, height)
        ctx.drawImage(video, 0, 0, width, height)

        // scan
        const imageData = ctx.getImageData(0, 0, width, height)
        code = jsqr(imageData.data, imageData.width, imageData.height)

        if (code) {
          console.log(code.data)
          this.code = code.data

          this.$router.push({
            name: 'Checkout',
            query: {
              voucher: code.data
            }
          })
          // ctxOutput.clearRect(0, 0, ctxOutput.width, ctxOutput.height)
          // ctxOutput.fillStyle = 'white'
          // ctxOutput.fillText(code.data, 10, 10)
          // ctxOutput.strokeStyle = 'red'
          // ctxOutput.strokeRect(
          //   code.location.topLeftCorner.x,
          //   code.location.topLeftCorner.y,
          //   code.location.bottomRightCorner.x - code.location.topLeftCorner.x,
          //   code.location.bottomRightCorner.y - code.location.topLeftCorner.y
          // )

          console.log('x:', code.location.topLeftCorner.x)
          console.log('y:', code.location.topLeftCorner.y)
          console.log('w:', code.location.bottomRightCorner.x - code.location.topLeftCorner.x)
          console.log('h:', code.location.bottomRightCorner.y - code.location.topLeftCorner.y)
          console.log('vw', width)
          console.log('cw', canvasOutput.width)
          console.log('vh', height)
          console.log('ch', canvasOutput.height)

          ctxOutput.strokeStyle = 'green'
          ctxOutput.strokeRect(10, 10, 100, 100)
        }
      }

      setTimeout(() => {
        if (!code) {
          requestAnimationFrame(perVideoFrame)
        }
      }, 100)
    }

    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
      .then(stream => {
        video.srcObject = stream
        video.setAttribute('playsinline', true) // required to tell iOS safari we don't want fullscreen
        video.play()

        // requestAnimationFrame(() => {
        // })
        requestAnimationFrame(perVideoFrame)
      })
  }
}
</script>

<style lang="scss" scoped>
section {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: white;

  video {
    width: 100%;
    height: 100%;
  }

  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
